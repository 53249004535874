import React from 'react'
import Layout from '../components/layout'
import Carousel from '../components/Carousel'


const Redshift = () => (
  <Layout>
    <header>
      <h1>REDSHIFT</h1>
    </header>
    <div className="carousel-container">
    <Carousel />
    </div>
  </Layout>
)

export default Redshift
